import { Platform, StyleSheet } from "react-native";

export default {
  ...StyleSheet.create({
    emptyComponent: {
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    emptyComponentActivityIndicator: {
      marginBottom: 8,
    },

    // MenuItem
    menuContainer: {
      marginBottom: 50,
      marginLeft: 8,
      width: 180,
      shadowRadius: 0.4,
      shadowOpacity: 0.1,
      borderRadius: 2,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      borderWidth: 1,
      elevation: 4,
      paddingTop: 8,
      paddingBottom: 8,
    },
    menuFirstItem: {
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 16,
    },
    menuLastItem: {
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 16,
    },
    menuItemTextMargin: {
      marginRight: 30,
    },

    // Messages
    chatRichCard: {
      flexDirection: "column",
      width: "100%",
    },
    chatTextContent: {
      width: "100%",
    },
    chatMessageMe: {
      marginTop: 16,
      flexDirection: "column",
      marginEnd: 16,
      marginStart: 78,
    },
    chatMessageBot: {
      marginTop: 16,
      flexDirection: "column",
      marginStart: 8,
      marginEnd: 48,
    },
    chatBubbleMeContainer: {
      borderRadius: 2,
      padding: 16,
      flex: 1,
      flexWrap: "wrap",
      flexDirection: "row",
      width: "100%",
    },
    chatBubbleBotContainer: {
      borderWidth: 1,
      paddingHorizontal: 16,
      paddingVertical: 16,
      flex: 1,
      flexWrap: "wrap",
      marginLeft: 8,
      flexDirection: "row",
      width: "100%",
    },
    chatBotRobot: {
      alignSelf: "flex-end",
    },
    renderMessageMargin: {
      marginTop: 24,
    },

    // Suggestions
    chatBubbleSuggestionContainer: {
      backgroundColor: "transparent",
      borderWidth: 1,
      borderRadius: 24,
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
    renderSuggestionItemMargin: {
      marginStart: 6,
    },
    renderSuggestionsContainer: {
      paddingBottom: 16,
      alignItems: "flex-start",
      overflow: "scroll",
    },
    renderSuggestionsLastItemMargin: {
      marginEnd: 6,
    },
    chatBubblePicContainer: {
      backgroundColor: "transparent",
      borderWidth: 1,
      borderRadius: 24,
      paddingBottom: 1,
      paddingLeft: 9,
      paddingRight: 17,
      flexDirection: "row",
      alignItems: "center",
    },

    // Card
    cardTitle: {
      marginTop: 8,
    },
    cardSubtitle: {
      marginTop: 4,
    },
    accordionItem: {
      marginTop: 8,
    },

    // WavingDots
    wavingDotsContainer: {
      marginHorizontal: 4,
      width: 8,
      height: 8,
      borderRadius: 4,
    },
    dotsLaoding: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flexDirectionRowReverse: {
      flexDirection: "row-reverse",
    },
    flexDirectionRow: {
      flexDirection: "row",
    },
    textPaddingFromDots: {
      paddingLeft: 4,
    },
    container: {
      flex: 1,
    },
    bottomContainer: {
      marginTop: 10,
      marginBottom: 10,
      flexDirection: "row",
      paddingVertical: 5,
      height: 50,
    },
    menuButton: {
      paddingHorizontal: 8,
      height: "100%",
      justifyContent: "center",
    },
    retryButton: {
      marginVertical: 16,
      marginHorizontal: 8,
    },
    sendButton: {
      marginHorizontal: 8,
    },
    textInput: {
      flex: 1,
      borderRadius: 20,
      borderWidth: 1,
      paddingHorizontal: 12,
      textAlign: "start",
    },
    typing: {
      marginTop: 16,
      flexDirection: "row",
      padding: 8,
      alignItems: "center",
    },
    textAlignLeft: {
      textAlign: "left",
    },
    textAlignRight: {
      textAlign: "right",
    },
  }),
  chatBotRobot: {
    alignSelf: "flex-end",
  },
  menuItemIconMargin: {
    marginRight: 11,
  },
  accordionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  accordionArrow: {
    marginLeft: "auto",
  },
  renderSuggestionsContainerWeb: {
    paddingBottom: 16,
    alignItems: "flex-start",
    overflow: "auto",
  },
};
