const uiTextKeys = {
  policy: {
    purchase: {
      headline: 'policy.firstMonthFree',
      subtitle: 'policy.your30DayFreeInsuranceHasStarted',
      buttonLink: 'auth.signOut',
      buttonPrimary: 'policy.completeRegistration',
    },
  },
};

module.exports = uiTextKeys;
