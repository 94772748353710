import React from "react";
import { useTheme } from "@suranceadmin/surance-ui-components";
import { View } from "react-native";
import Chat from "./Chat";
import styles from "./styles";

const ChatContainer = () => {
  const theme = useTheme();
  return (
    <View
      style={[
        styles.root,
        {
          borderColor: theme.colors.line.light,
          borderWidth: 1,
        },
      ]}
    >
      <Chat />
    </View>
  );
};
export default ChatContainer;
