import { ThemeProvider as SuranceThemeProvider } from "@suranceadmin/surance-ui-components";
import theme from "./themes/light.theme";
import Container from "./chat";
import { useFonts } from "expo-font";
import AppLoading from "expo-app-loading";
import Locale from "./locale";
const { IntlProvider, currentLocale } = Locale;
import { I18nManager } from "react-native";

export default function App() {
  const [fontsLoaded] = useFonts(theme.fontDefinitions);
  const isRTL = currentLocale === "he";
  I18nManager.allowRTL(isRTL);
  I18nManager.forceRTL(isRTL);
  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <IntlProvider key={currentLocale} locale={currentLocale}>
        <SuranceThemeProvider theme={{ ...theme, ...{ isRTL } }}>
          <Container />
        </SuranceThemeProvider>
      </IntlProvider>
    );
  }
}
