import axios from "axios";
// VS: https://docs.expo.dev/guides/environment-variables/
import Constants from "expo-constants";
const baseUrl = Constants.manifest.baseUrl;

export async function startChat(chatId: string) {
  console.log("Will initialize chat");
  const response = await axios.post(`${baseUrl}/api/chat/start`, {
    // VS: Currently only one chat per user is supported
    sessionId: chatId,
    userId: chatId,
  });
  const responseData = response.data;
  console.log("Response", responseData);
  return responseData;
}

export async function sendQuery(chatId: string, messageObject: any) {
  const content = messageObject.content;
  console.log("Will send message", JSON.stringify(content));
  const response = await axios.post(`${baseUrl}/api/chat/send`, {
    message: messageObject,
    // VS: Currently only one chat per user is supported
    sessionId: chatId,
    userId: chatId,
  });
  const responseData = response.data;
  console.log("Response", responseData);
  return responseData;
}

export async function restartChat(chatId: string) {
  console.log("Will restart chat");
  const response = await axios.post(`${baseUrl}/api/chat/restart`, {
    // VS: Currently only one chat per user is supported
    sessionId: chatId,
    userId: chatId,
  });
  const responseData = response.data;
  console.log("Response", responseData);
  return responseData;
}

// "content":{"text":{"text":["menu"]}}