// VS: Not using embedded fonts, trying to load from Google CDN
// import { BebasNeue_400Regular } from "@expo-google-fonts/bebas-neue";
// import { PTSans_400Regular, PTSans_700Bold } from "@expo-google-fonts/pt-sans";
import {
  PTSansNarrow_400Regular,
  PTSansNarrow_700Bold,
} from "@expo-google-fonts/pt-sans-narrow";
import Locale from "./../locale";
const { currentLocale } = Locale;

const Colors = {
  background: {
    white: "#FFFFFF",
    default: "#F0F0F0",
    header: "#012230",
    headerModal: "#052E40",
    important: "#F9EEE2",
    warning: "#CB3E5A",
  },
  button: {
    primary: "#01B0C1",
    secondary: "#FFFFFF",
    negative: "#CB3E5A",
    disabled: "#DDE2E8",
    secondaryBorder: "#01B0C1",
    transparentBorder: "#FFFFFF",
  },
  text: {
    default: "#052E40",
    light: "#546B84",
    ondark: "#FFFFFF",
    disabled: "#93A3B6",
    link: "#01B0C1",
    warning: "#CB3E5A",
    buttonSecondary: "#052E40",
  },
  line: {
    default: "#93A3B6",
    light: "#DDE2E8",
  },
  level: {
    unknown: "#93A3B6",
    notSafe: "#CB3E5A",
    mildlySafe: "#DD8206",
    fullySafe: "#3E78C7",
  },
  notification: {
    critical: "#CB3E5A",
    important: "#DD8206",
    improvement: "#E6AF0B",
  },
  icon: {
    default: "#052E40",
    link: "#01B0C1",
    ondark: "#FFFFFF",
    warning: "#CB3E5A",
    critical: "#CB3E5A",
    important: "#DB7B2B",
    improvement: "#E6AF0B",
    valid: "#01B0C1",
  },
};

const FontsEnglish = {
  headline1: {
    fontFamily: "BebasNeue-Regular",
    fontSize: 42,
    lineHeight: 42,
  },
  headline2: {
    fontFamily: "BebasNeue-Regular",
    fontSize: 32,
    lineHeight: 32,
  },
  subtitle: {
    fontFamily: "PTSansNarrow-Regular",
    fontSize: 24,
    lineHeight: 28,
  },
  subtitleBold: {
    fontFamily: "PTSansNarrow-Bold",
    fontSize: 24,
    lineHeight: 28,
  },
  body: {
    fontFamily: "PTSans-Regular",
    fontSize: 18,
    lineHeight: 24,
  },
  bodyBold: {
    fontFamily: "PTSans-Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  bodyWide: {
    fontFamily: "PTSans-Regular",
    fontSize: 18,
    lineHeight: 28,
  },
  buttonPrimary: {
    fontFamily: "BebasNeue-Regular",
    fontSize: 18,
    lineHeight: 16,
    textTransform: "uppercase",
    letterSpacing: 1,
    marginBottom: -3.5,
    marginTop: -0.5,
  },
  buttonSecondary: {
    fontFamily: "PTSansNarrow-Regular",
    fontSize: 16,
    lineHeight: 16,
  },
  overline: {
    fontFamily: "PTSans-Regular",
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  overlineBold: {
    fontFamily: "PTSans-Bold",
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  caption1: {
    fontFamily: "PTSans-Regular",
    fontSize: 16,
    lineHeight: 20,
  },
  caption1Bold: {
    fontFamily: "PTSans-Bold",
    fontSize: 16,
    lineHeight: 20,
  },
  caption2: {
    fontFamily: "PTSans-Regular",
    fontSize: 14,
    lineHeight: 16,
  },
  caption2Bold: {
    fontFamily: "PTSans-Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  caption3: {
    fontFamily: "PTSans-Regular",
    fontSize: 12,
    lineHeight: 16,
  },
  caption3Bold: {
    fontFamily: "PTSans-Bold",
    fontSize: 12,
    lineHeight: 16,
  },
};

const fontRegular = "OpenSansHebrew-Regular";
const fontBold = "OpenSansHebrew-Bold";

const FontsHebrew = {
  headline1: {
    fontFamily: fontRegular,
    fontSize: 38,
    lineHeight: 40,
    fontWeight: "700",
  },
  headline2: {
    fontFamily: fontRegular,
    fontSize: 28,
    lineHeight: 32,
    fontWeight: "700",
  },
  subtitle: {
    fontFamily: fontRegular,
    fontSize: 20,
    lineHeight: 28,
    fontWeight: "400",
  },
  subtitleBold: {
    fontFamily: fontBold,
    fontSize: 20,
    lineHeight: 28,
    fontWeight: "700",
  },
  body: {
    fontFamily: fontRegular,
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "400",
  },
  bodyBold: {
    fontFamily: fontBold,
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "700",
  },
  bodyWide: {
    fontFamily: fontRegular,
    fontSize: 18,
    lineHeight: 28,
    fontWeight: "400",
  },
  buttonPrimary: {
    fontFamily: fontBold,
    fontSize: 14,
    lineHeight: 16,
    textTransform: "uppercase",
    letterSpacing: 1,
    fontWeight: "700",
  },
  buttonSecondary: {
    fontFamily: fontRegular,
    fontSize: 16,
    lineHeight: 16,
    fontWeight: "400",
  },
  overline: {
    fontFamily: fontRegular,
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
    fontWeight: "400",
  },
  overlineBold: {
    fontFamily: fontBold,
    fontSize: 16,
    lineHeight: 16,
    textTransform: "uppercase",
    fontWeight: "700",
    paddingTop: 2,
    marginBottom: -2,
  },
  caption1: {
    fontFamily: fontRegular,
    fontSize: 16,
    lineHeight: 20,
    fontWeight: "400",
  },
  caption1Bold: {
    fontFamily: fontBold,
    fontSize: 16,
    lineHeight: 20,
    fontWeight: "700",
  },
  caption2: {
    fontFamily: fontRegular,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "400",
  },
  caption2Bold: {
    fontFamily: fontBold,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "700",
  },
  caption3: {
    fontFamily: fontRegular,
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "400",
  },
  caption3Bold: {
    fontFamily: fontBold,
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "700",
  },
};

const fontDefinitionsEnglish = {
  "BebasNeue-Regular":
    "https://fonts.gstatic.com/s/bebasneue/v1/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf",
  "PTSans-Regular":
    "https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79P0WOxOGMMDQ.ttf",
  "PTSans-Bold":
    "https://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf",
  "PTSansNarrow-Regular":
    "https://fonts.gstatic.com/s/ptsansnarrow/v11/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf",
  "PTSansNarrow-Bold":
    "https://fonts.gstatic.com/s/ptsansnarrow/v11/BngSUXNadjH0qYEzV7ab-oWlsbg95DiCUfzgRd-3.ttf",
};

const fontDefinitionsHebrew = {
  "OpenSansHebrew-Regular":
    "https://fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Regular.ttf",
  "OpenSansHebrew-Bold":
    "https://fonts.gstatic.com/ea/opensanshebrew/v3/OpenSansHebrew-Bold.ttf",
};

const Theme = {
  colors: Colors,
  fonts: currentLocale === "he" ? FontsHebrew : FontsEnglish,
  fontDefinitions:
    currentLocale === "he" ? fontDefinitionsHebrew : fontDefinitionsEnglish,
};

export default Theme;
