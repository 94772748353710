import { Platform, StyleSheet } from "react-native";

export default {
  ...StyleSheet.create({
    root: {
      maxWidth: 600,
      minHeight: 300,
      height: "100%",
      width: "100%",
      alignSelf: "center",
    },
  }),
};
