import React, {useState, useEffect, useRef} from 'react';
import {Animated, Easing} from 'react-native';
import {TextType, Text} from '@suranceadmin/surance-ui-components';
import styles from './styles';

function WavingDots({text, dots, color, theme}) {
  const [animations, setAnimations] = useState([]);
  const [reverse, setReverse] = useState(false);

  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const dotAnimations = [];
    for (let i = 0; i < dots; i++) {
      dotAnimations.push(new Animated.Value(0));
    }
    setAnimations(dotAnimations);
  }, []);

  useEffect(() => {
    loadingAnimation(animations);
    appearAnimation();
  }, []);

  function appearAnimation() {
    Animated.timing(opacity, {
      toValue: 1,
      easing: Easing.ease,
      useNativeDriver: true,
    }).start();
  }

  function floatAnimation(node, delay) {
    const floatSequence = Animated.sequence([
      Animated.timing(node, {
        toValue: -5,
        easing: Easing.bezier(0.41, -0.15, 0.56, 1.21),
        delay,
        useNativeDriver: true,
      }),
      Animated.timing(node, {
        toValue: 0,
        easing: Easing.bezier(0.41, -0.15, 0.56, 1.21),
        delay,
        useNativeDriver: true,
      }),
      Animated.timing(node, {
        toValue: 0,
        delay,
        useNativeDriver: true,
      }),
    ]);
    return floatSequence;
  }

  function loadingAnimation(nodes) {
    Animated.parallel(
      nodes.map((node, index) => floatAnimation(node, index * 100)),
    ).start(() => {
      setReverse(!reverse);
    });
  }

  useEffect(() => {
    loadingAnimation(animations, reverse);
  }, [reverse]);

  return (
    <Animated.View
      style={[
        styles.dotsLaoding,
        {opacity},
        theme.isRTL ? styles.flexDirectionRowReverse : styles.flexDirectionRow,
      ]}>
      {animations.map((animation, index) => (
        <Animated.View
          key={index.toString()}
          style={[
            styles.wavingDotsContainer,
            {
              backgroundColor: color || '#000000',
            },
            {transform: [{translateY: animation}]},
          ]}
        />
      ))}
      {text && (
        <Text style={styles.textPaddingFromDots} type={TextType.Caption2}>
          {text}
        </Text>
      )}
    </Animated.View>
  );
}

export default WavingDots;
