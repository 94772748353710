import React, { useCallback } from "react";
import { View, FlatList, TouchableOpacity, Platform } from "react-native";
import styles from "./styles";
import { ItemSeparator } from "./ItemSeparator";
import { TextType, Text } from "@suranceadmin/surance-ui-components";

const ChatBubbleSuggestion = ({ text, theme, hasDevices }) => {
  return (
    <View
      style={
        hasDevices
          ? [
              styles.chatBubblePicContainer,
              { borderColor: theme.colors.line.default },
            ]
          : [
              styles.chatBubbleSuggestionContainer,
              { borderColor: theme.colors.line.default },
            ]
      }
    >
      <Text style={styles.textAlignLeft} type={TextType.Caption2}>
        {text}
      </Text>
    </View>
  );
};

export const RenderSuggestionItem = ({ item, onClick, theme, hasDevices }) => {
  return (
    <TouchableOpacity
      style={styles.renderSuggestionItemMargin}
      onPress={() => {
        onClick(item);
      }}
    >
      <ChatBubbleSuggestion
        text={item.caption}
        theme={theme}
        hasDevices={hasDevices}
      />
    </TouchableOpacity>
  );
};

export const RenderSuggestions = ({
  suggestions,
  suggestionsDevices,
  onClick,
  displayTypes,
  theme,
}) => {
  const hasDevices = useCallback(
    (item) =>
      suggestionsDevices.length === 0
        ? displayTypes.includes(item)
        : displayTypes.some((r) => suggestionsDevices.indexOf(r) >= 0),
    [suggestionsDevices, displayTypes]
  );

  const renderItem = useCallback(
    ({ item }) => (
      <RenderSuggestionItem
        item={item}
        onClick={onClick}
        theme={theme}
        hasDevices={hasDevices(item)}
      />
    ),
    [onClick, suggestionsDevices, hasDevices]
  );
  const SuggestionsList = useCallback(
    () => (
      <FlatList
        ListFooterComponent={
          <View style={styles.renderSuggestionsLastItemMargin} />
        }
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        data={suggestions}
        renderItem={renderItem}
        keyExtractor={(item) => item?.caption}
      />
    ),
    [renderItem, suggestions]
  );
  return suggestions?.length > 0 ? (
    <>
      <ItemSeparator marginTop={0} marginBottom={15} marginHorizontal={0} />
      {Platform.OS === "web" ? (
        <div style={styles.renderSuggestionsContainerWeb}>
          <SuggestionsList />
        </div>
      ) : (
        <View style={styles.renderSuggestionsContainer}>
          <SuggestionsList />
        </View>
      )}
      <ItemSeparator marginTop={0} marginBottom={0} marginHorizontal={0} />
    </>
  ) : (
    <ItemSeparator marginTop={0} marginBottom={0} marginHorizontal={0} />
  );
};
