const en = {
  common: {
    // UI actions
    skip: 'Skip',
    continue: 'Continue',
    next: 'Next',
    cancel: 'Cancel',
    go: 'Go',
    OK: 'OK',
    no: 'No',
    yes: 'Yes',
    later: 'Later',
    exit: 'Exit',
    error: 'Error',
    sorry: 'Sorry',
    verify: 'Verify',
    done: 'Done',
    delete: 'Delete',
    enable: 'Enable',
    restart: 'Restart',
    restartChat: 'Restart chat',
    remove: 'Remove',
    completed: 'Completed',
    pressConfirm: 'Press Confirm',
    takeActionNow: 'Take Action Now',
    get: 'Get',
    close: 'Close',
    retry: 'Retry',
    status: 'Status',
    checkAgain: 'Check again',
    pending: 'Pending',
    login: 'Login',
    password: 'Password',
    routerName: 'Router Name',
    invalidEntity: 'Invalid {entity}',
    resolved: 'Resolved',
    yesImIn: 'Yes, I’m in',
    subject: 'Subject',

    // Notification date format
    sec: '{seconds}s',
    min: '{minutes}m',
    hour: '{hours}h',
    day: '{days}d',
    week: '{weeks}w',
    year: '{years}y',

    // UI actions capitalized
    nextCapitalized: 'NEXT',
    confirmCapitalized: 'CONFIRM',
    selectCapitalized: 'SELECT',
    sendCapitalized: 'SEND',
    back: 'Back',
    exitAppCapitalized: 'EXIT APP',
    saveChangesCapitalized: 'SAVE CHANGES',
    getStartedCapitalized: 'GET STARTED',

    // Lower case words
    more: 'more',
    and: 'and',
    critical: 'critical',
    important: 'important',
    improvement: 'improvement',
    all: 'all',

    // Upper case words
    country: 'Country',
    breaches: 'Breaches',
    network: 'Network',
    permissions: 'Permissions',
    notifications: 'Notifications',
    tasks: 'Tasks',
    device: 'Device',
    chatbot: 'Chatbot',
    help: 'Help',
    email: 'Email',
    home: 'Home',
    manufacture: 'Manufacture',
    manufacturer: 'Manufacturer',
    scan: 'Scan',
    identity: 'Identity',
    rewards: 'Rewards',
    insurance: 'Insurance',
    feedback: 'Feedback',
    identityCoverage: 'Identity Coverage',
    name: 'Name',
    nickname: 'Nickname',
    type: 'Type',
    my: 'My',
    yourPhone: 'Your phone',
    addPicture: 'Add picture',
    seen: 'Seen',
    new: 'New',
    completeSurvey: 'Complete survey',
    phoneCall: 'Phone Call',
    account: 'Account',
    message: 'Message',
    noted: 'Noted',
    screenshot: 'Screenshot',
    text: 'Text',
    qrCode: 'QR code',
    // Exclaimed
    great: 'Great!',
    allDone: 'All done!',
    irreversibleStep: 'This step is irreversible!',

    // Other
    typeHint: 'Type...',
    ohNo: 'Oh no',
    notYet: 'Not yet',
    powered: 'Powered by Surance.io',
    poweredByCyberman: 'Powered by Cyberman365<break></break>and Surance.io',
    imageTooLarge: 'Image is too large',
    somethingWentWrong:
      'Oops - we are sorry that something unexpected went wrong.',
    welcome: 'Welcome{name, select, null {} other {, {name}}}!',
    turnOffPhone: 'Do not turn off your phone',
    reasonForRemoving: 'Reason for removing',
    goodNews: 'Good news!',
    changeEmail: 'Change email',
    seemsToBeAnError:
      'There seems to have been an error - Please reopen the app.',
    somethingWrong:
      'Something went wrong. Sorry for the inconvenience. Please try again later or contact support.',
    remindMeLater: 'Remind me later',
    code: 'Code',
    beta: 'Beta',
    connecting: 'Connecting...',
  },
  chatbot: {
    chatIsEmpty: 'This chat is empty',
    failedToConnectRetry: 'Failed to connect to the chat. Please retry.',
    failedToRestartRetry: 'Failed to restart the chat. Please retry.',
  }
};

module.exports = en;
