import React from "react";
import { TouchableOpacity, Modal, View } from "react-native";
import { TextType, Text } from "@suranceadmin/surance-ui-components";
import {
  MenuProvider,
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { ChatbotRestart, ChatbotPic } from "@suranceadmin/icons/native";
import styles from "./styles";
import rootStyles from "../styles";
import { useIntl } from "react-intl";


export const MenuList = ({ theme, visible, menuToggle, onRestart }) => {
  const intl = useIntl();
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
    >
      <View
        style={[rootStyles.root, {
          justifyContent: "flex-end",
        }]}
      >
        <MenuProvider skipInstanceCheck={true}>
          <TouchableOpacity
            style={styles.container}
            activeOpacity={1}
            onPress={menuToggle}
          />
          <Menu>
            <MenuTrigger>
              <TouchableOpacity activeOpacity={1} onPress={menuToggle}>
                <MenuOptions
                  customStyles={{
                    optionsWrapper: [
                      styles.menuContainer,
                      {
                        backgroundColor: theme.colors.background.white,
                        borderColor: theme.colors.line.light,
                        shadowColor: theme.colors.text.light,
                      },
                    ],
                  }}
                >
                  {onRestart && (
                    <MenuOption
                      style={styles.menuLastItem}
                      onSelect={() => {
                        menuToggle();
                        onRestart();
                      }}
                    >
                      <ChatbotRestart
                        style={styles.menuItemIconMargin}
                        color={theme.colors.icon.link}
                      />
                      <Text
                        style={styles.menuItemTextMargin}
                        type={TextType.Caption1}
                        color={theme.colors.text.link}
                      >
                        {intl.formatMessage({ id: "common.restartChat" })}
                      </Text>
                    </MenuOption>
                  )}
                </MenuOptions>
              </TouchableOpacity>
            </MenuTrigger>
            <MenuOptions>
              <MenuOption />
            </MenuOptions>
          </Menu>
        </MenuProvider>
      </View>
    </Modal>
  );
};
