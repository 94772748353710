import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/he";
import { I18nManager } from "react-native";
import Constants from "expo-constants";
// import RNRestart from 'react-native-restart';
import React, { useContext, createContext, useEffect, useState } from "react";
// import enAccessibility from './accessibility/en-accessibility';
// import {useSelector} from 'react-redux';
// import API from '@components/common/api';
import { useIntl as useReactIntl, IntlProvider } from "react-intl";
import defaultKeys from "./translations/uiTextKeys";
// import deepmerge from 'deepmerge';
import { getParsedTranslations, getMissingKeys } from "./utils";

// VS: Imports with * created an object with default alias
// and resulted in extra keys being added to parsed translations
const en = require("./translations/en");
const he = require("./translations/he");

const translations = getParsedTranslations({ en, he });

export const checkRtlState = (locale) => {
  const isRtl = locale === "he";
  if (isRtl !== I18nManager.isRTL) {
    I18nManager.allowRTL(isRtl);
    I18nManager.forceRTL(isRtl);
    // RNRestart.Restart();
  }
};

const IntlProviderRtl = ({ locale, ...props }) => {
  const [transl, setTransl] = useState(translations[locale]);
  // const hasRemoteTranslations = useSelector(
  //   ({user}) => user?.hasRemoteTranslations,
  // );

  useEffect(() => {
    checkRtlState(locale);
  }, [locale]);

  // useEffect(() => {
  //   if (hasRemoteTranslations && API?.definitions?.translations?.texts) {
  //     const remoteTransl = getParsedTranslations(
  //       API.definitions.translations.texts,
  //     );
  //     if (__DEV__) {
  //       const missingKeys = getMissingKeys(
  //         getParsedTranslations(API.definitions.translations.texts),
  //       );
  //       missingKeys.forEach((x) => console.error(x));
  //     }
  //     const defaultTranslations = translations[locale];
  //     setTransl({...defaultTranslations, ...remoteTransl[locale]});
  //   }
  // }, [hasRemoteTranslations, locale]);

  return (
    <IntlProvider locale={locale} messages={transl}>
      <UITextKeyProvider {...props} />
    </IntlProvider>
  );
};

const KeyContextContext = createContext();

export const UITextKeyProvider = ({ children }) => {
  const [uiTextKeys, setUITextKeys] = useState(defaultKeys);
  // const hasRemoteTranslations = useSelector(
  //   ({user}) => user?.hasRemoteTranslations,
  // );

  // useEffect(() => {
  //   if (hasRemoteTranslations) {
  //     const remoteKeys = API.definitions?.translations?.uiTextKeys || {};
  //     setUITextKeys(deepmerge(defaultKeys, remoteKeys));
  //   }
  // }, [hasRemoteTranslations]);

  return (
    <KeyContextContext.Provider value={{ uiTextKeys }}>
      {children}
    </KeyContextContext.Provider>
  );
};

export const useIntl = () => {
  const context = useContext(KeyContextContext);
  const intl = useReactIntl();
  if (context === undefined) {
    throw new Error("useIntl must be used within UITextKeyProvider");
  }

  return { ...context, ...intl };
};

if (__DEV__) {
  const missingKeys = getMissingKeys(translations);
  missingKeys.forEach((x) => console.error(x));
}

export default {
  IntlProvider: IntlProviderRtl,
  translations,
  currentLocale: Constants.manifest.locale || "en",
};
