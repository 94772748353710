const he = {
  common: {
    // UI actions
    skip: 'דלג',
    continue: 'המשך',
    next: 'המשך',
    cancel: 'ביטול',
    go: 'כנס',
    OK: 'אוקי',
    no: 'לא',
    yes: 'כן',
    later: 'אחר כך',
    exit: 'יציאה',
    error: 'שגיאה',
    sorry: 'מתנצלים',
    verify: 'אימות',
    done: 'סיום',
    delete: 'מחק',
    enable: 'אפשר',
    restart: 'ריסטרט',
    restartChat: 'הפעל מחדש',
    remove: 'הסר',
    completed: 'הושלם',
    pressConfirm: 'לחץ/י אישור',
    takeActionNow: 'פעל כעת',
    get: 'קבל',
    close: 'סגור',
    retry: 'כניסה מחדש',
    status: 'סטטוס',
    pending: 'ממתין',
    checkAgain: 'בדוק שוב',
    login: 'התחברות',
    password: 'סיסמא',
    routerName: 'שם הראוטר',
    invalidEntity: 'שגיאת {entity}',
    resolved: 'פתור',
    yesImIn: 'כן, אני בפנים',
    subject: 'נושא',
    text: 'טקסט',
    qrCode: 'ברקוד',
    screenshot: 'צילום מסך',
    // Notification date format
    sec: '{seconds, plural, =1 {שניה #} other {# שניות}}',
    min: '{minutes, plural, =1 {דקה #} other {# דקות}}',
    hour: '{hours, plural, =1 {שעה #} other {# שעות}}',
    day: '{days, plural, =1 {יום #} other {# ימים}}',
    week: '{weeks, plural, =1 {שבוע #} other {# שבועות}}',
    year: '{years, plural, =1 {שנה #} other {# שנים}}',

    // UI actions capitalized
    nextCapitalized: 'המשך',
    confirmCapitalized: 'אישור',
    selectCapitalized: 'בחר',
    sendCapitalized: 'שלח',
    back: 'חזור',
    exitAppCapitalized: 'יציאה מהאפליקציה',
    saveChangesCapitalized: 'שמור שינויים',
    getStartedCapitalized: 'התחל',

    // Lower case words
    more: 'עוד',
    and: 'ו',
    critical: 'קריטי',
    important: 'חשוב',
    improvement: 'שיפור',
    all: 'הכל',

    // Upper case started words
    country: 'מדינה',
    breaches: 'פרצות',
    network: 'רשת',
    permissions: 'הרשאות',
    notifications: 'התראות',
    tasks: 'משימות',
    device: 'מכשיר',
    chatbot: 'צאטבוט',
    help: 'עזרה',
    email: 'אמייל',
    home: 'בית',
    manufacture: 'יצרן',
    manufacturer: 'יצרן',
    scan: 'סריקה',
    identity: 'זהות',
    rewards: 'פרסים',
    insurance: 'ביטוח',
    feedback: 'משוב',
    identityCoverage: 'כיסוי זהות',
    name: 'שם',
    nickname: 'כינוי',
    type: 'סוג',
    my: 'שלי',
    completeSurvey: 'השלם סקר',
    yourPhone: 'הטלפון שלך',
    addPicture: 'הוסיפו תמונה',
    seen: 'נראה',
    new: 'חדש',
    phoneCall: 'שיחת טלפון',
    account: 'משתמש',
    message: 'הודעה',
    noted: 'Noted',

    // Exclaimed
    great: 'מעולה!',
    allDone: 'הכל מוכן!',
    irreversibleStep: 'צעד בלתי הפיך!',

    // Other
    typeHint: 'הקלד...',
    ohNo: 'אוי לא',
    notYet: 'עוד לא',
    powered: 'Powered by Surance.io',
    poweredByCyberman: 'Powered by Cyberman365<break></break>and Surance.io',
    imageTooLarge: 'תמונה גדולה מדי',
    somethingWentWrong: 'אופס - אנו מצטערים שקרה משהו לא כשורה.',
    welcome: 'ברוך הבא{name, select, null {} other {, {name}}}!',
    turnOffPhone: 'נא לא לכבות את הטלפון',
    reasonForRemoving: 'סיבה להסרה',
    goodNews: 'חדשות טובות!',
    changeEmail: 'שנה/י אמייל',
    seemsToBeAnError: 'נתקלתם בשגיאה לא ידועה - אנא פתחו את האפליקציה מחדש.',
    somethingWrong:
      'קרתה תקלה. אנו מצטערים על אי הנוחות. אנא נסו שוב מאוחר יותר או צרו קשר עם התמיכה.',
    remindMeLater: 'אעשה זאת מאוחר יותר',
    code: 'קוד',
    beta: 'בטא',
    connecting: 'מתחבר...',
  },
  chatbot: {
    chatIsEmpty: 'הצאט ריק',
    failedToConnectRetry: 'התחברות לצאט נכשלה. אנא נסה שוב.',
    failedToRestartRetry: 'הפעלה מחדש של הצאט נכשלה. אנא נסה שוב.'
  }
};

module.exports = he;
