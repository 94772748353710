import React from "react";
import {
  useTheme,
  Button,
  ButtonType,
  TextType,
  Text,
} from "@suranceadmin/surance-ui-components";
import styles from "./styles";
import { View, ActivityIndicator } from "react-native";
import { useIntl } from "react-intl";

export enum InitializationState {
  Initializing,
  Initialized,
  Failed,
  RestartFailed,
}

export const EmptyView = ({ initState, onRetry }) => {
  const theme = useTheme();
  const intl = useIntl();
  const showRetryButton =
    initState !== InitializationState.Initialized &&
    initState !== InitializationState.Initializing;
  return (
    <View style={styles.emptyComponent}>
      {!showRetryButton && (
        <ActivityIndicator
          style={styles.emptyComponentActivityIndicator}
          size="large"
          color={theme.colors.text.light}
        />
      )}
      {initState === InitializationState.Initialized && (
        <Text type={TextType.Body}>
          {intl.formatMessage({ id: "chatbot.chatIsEmpty" })}
        </Text>
      )}
      {initState === InitializationState.Failed && (
        <Text type={TextType.Body}>
          {intl.formatMessage({ id: "chatbot.failedToConnectRetry" })}
        </Text>
      )}
      {initState === InitializationState.RestartFailed && (
        <Text type={TextType.Body}>
          {intl.formatMessage({ id: "chatbot.failedToRestartRetry" })}
        </Text>
      )}
      {initState === InitializationState.Initializing && (
        <Text type={TextType.Body}>
          {intl.formatMessage({ id: "common.connecting" })}
        </Text>
      )}
      {showRetryButton && (
        <Button
          type={ButtonType.Primary}
          onPress={onRetry}
          style={styles.retryButton}
        >
          {intl.formatMessage({ id: "common.retry" })}
        </Button>
      )}
    </View>
  );
};
