import React from 'react';
import {View, StyleSheet} from 'react-native';
import {useTheme} from '@suranceadmin/surance-ui-components';

export const ItemSeparator = ({marginTop, marginBottom, marginHorizontal}) => {
  const theme = useTheme();
  return (
    <View
      style={[
        styles.separator,
        {
          backgroundColor: theme.colors.line.light,
          marginTop,
          marginBottom,
          marginHorizontal,
        },
      ]}
    />
  );
};

const styles = StyleSheet.create({
  separator: {
    height: 1,
  },
});
