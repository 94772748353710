import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  useTheme,
  Button,
  ButtonType,
} from "@suranceadmin/surance-ui-components";
import styles from "./components/styles";
import { View, TextInput, FlatList, TouchableOpacity } from "react-native";
import ChatbotMenuActive from "@suranceadmin/icons/web/ChatbotMenuActive";
import ChatbotMenuDefault from "@suranceadmin/icons/web/ChatbotMenuDefault";
import { MenuList } from "./components/MenuList";
import { RenderSuggestions } from "./components/Suggestions";
import { createMessageObject, getSuggestions, getChatId } from "./Utils";
import { sendQuery, startChat, restartChat } from "./api";
import { RenderMessage } from "./components/ChatBubbles";
import { TypingIndicator } from "./components/TypingIndicator";
import { InitializationState, EmptyView } from "./components/EmptyView";
import { useIntl } from "react-intl";

const ChatScreen = () => {
  const [chatId] = useState(getChatId());
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showBotTypingLoader, setShowBotTypingLoader] = useState(false);
  const [menuItemClicked, setMenuItemClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [initializationState, setInitializationState] = useState(
    InitializationState.Initializing
  );

  const intl = useIntl();
  const listRef = useRef();
  const theme = useTheme();
  const sendEnabled = message && message.trim().length > 0;
  const lastMessage =
    messages.length > 0 ? messages[messages.length - 1] : undefined;
  const suggestions = getSuggestions(lastMessage);
  const hasSuggestions = suggestions?.length > 0;
  const isSendBlocked =
    initializationState !== InitializationState.Initialized ||
    showBotTypingLoader;
  const inputDisabled = hasSuggestions || isSendBlocked;
  const initializeChat = async () => {
    console.log("Will initialize");
    setInitializationState(InitializationState.Initializing);
    try {
      setShowBotTypingLoader(false);
      setMessages([]);
      const responseData = await startChat(chatId);
      processResponse(responseData, []);
      setInitializationState(InitializationState.Initialized);
      console.log("Initialized");
    } catch (error) {
      console.log("Failed to initialize chat", error);
      setInitializationState(InitializationState.Failed);
    }
  };

  const sendMessage = async (messageToSend) => {
    console.log("Will send message", messageToSend);
    try {
      const messageObject = createMessageObject(messageToSend);
      let newMessages = messages.concat([messageObject]);
      setMessages(newMessages);
      setShowBotTypingLoader(true);
      const responseData = await sendQuery(chatId, messageObject);
      processResponse(responseData, newMessages);
      setShowBotTypingLoader(false);
    } catch (error) {
      console.log("Failed to send message", error);
      // VS: Temporary approach to error handling - restarting the chat should fix the issues
      setMessages([]);
      setInitializationState(InitializationState.Failed);
      setShowBotTypingLoader(false);
    }
  };

  const processResponse = (dfResponse: any, currentMessages: any[]) => {
    const newMessages = currentMessages.concat(dfResponse.messages);
    setMessages(newMessages);
  };

  const restartChatSession = async () => {
    setInitializationState(InitializationState.Initializing);
    console.log("Will restart");

    try {
      setShowBotTypingLoader(false);
      setMessages([]);
      const responseData = await restartChat(chatId);
      processResponse(responseData, []);
      setInitializationState(InitializationState.Initialized);
      console.log("Restarted");
    } catch (error) {
      console.log("Failed to restart chat", error);
      setInitializationState(InitializationState.RestartFailed);
    }
  };

  useEffect(() => {
    (async () => {
      await initializeChat();
    })();
  }, []);

  const menuToggle = useCallback(() => {
    setMenuItemClicked(!menuItemClicked);
    setShowModal(!showModal);
  }, [menuItemClicked, showModal]);

  const sendCurrentMessage = async () => {
    if (!sendEnabled) {
      console.log("Sending disabled");
      return;
    }
    const messageToSend = message;
    setMessage("");
    await sendMessage(messageToSend);
  };

  const retryInitialization = async () => {
    if (initializationState === InitializationState.RestartFailed) {
      await restartChatSession();
    } else {
      await initializeChat();
    }
  };

  const scrollToEnd = () => {
    // VS: Timeout is needed so as layout may change because of suggestion menu
    setTimeout(() => listRef?.current?.scrollToEnd({ animated: true }), 200);
  };

  return (
    <View style={styles.container}>
      <FlatList
        // VS: On web height is requred or the flatlist will have no scroll - will grow with content
        style={{ ...styles.container, ...{ height: 0 } }}
        ref={listRef}
        onContentSizeChange={() => {
          console.log("Content size changed");
          scrollToEnd();
        }}
        data={messages}
        renderItem={({ item, index }) => (
          <RenderMessage
            isLast={messages.length - 1 === index}
            item={item}
            index={index}
            onClick={async (clickedText) => {
              await sendMessage(clickedText);
            }}
            theme={theme}
          />
        )}
        ListFooterComponent={
          <View style={styles.renderSuggestionsMargin}>
            {showBotTypingLoader && <TypingIndicator theme={theme} />}
          </View>
        }
        keyExtractor={({ id }) => id.toString()}
        contentContainerStyle={{ flexGrow: 1 }}
        ListEmptyComponent={
          <EmptyView
            initState={initializationState}
            onRetry={retryInitialization}
          />
        }
      />
      <View>
        <RenderSuggestions
          suggestions={suggestions}
          suggestionsDevices={[]}
          onClick={async (suggestion) => {
            await sendMessage(suggestion);
          }}
          displayTypes={[]}
          theme={theme}
        />
        <View style={styles.bottomContainer}>
          <TouchableOpacity
            style={styles.menuButton}
            onPress={() => {
              menuToggle();
            }}
          >
            {menuItemClicked ? (
              <ChatbotMenuActive color={theme.colors.icon.link} />
            ) : (
              <ChatbotMenuDefault color={theme.colors.icon.link} />
            )}
          </TouchableOpacity>
          <TextInput
            editable={!hasSuggestions}
            underlineColorAndroid="transparent"
            style={[
              styles.textInput,
              {
                borderColor: inputDisabled
                  ? theme.colors.line.light
                  : theme.colors.line.default,
              },
              // theme.isRTL ? styles.textAlignRight : styles.textAlignLeft,
            ]}
            onChangeText={(text) => !isSendBlocked && setMessage(text)}
            value={message}
            onKeyPress={async (event) => {
              if (event.key === "Enter") {
                await sendCurrentMessage();
              }
            }}
            maxLength={256}
          />
          <Button
            type={ButtonType.Primary}
            disabled={!sendEnabled}
            onPress={async () => {
              await sendCurrentMessage();
            }}
            style={styles.sendButton}
          >
            {intl.formatMessage({ id: "common.sendCapitalized" })}
          </Button>
        </View>
      </View>
      <MenuList
        visible={showModal}
        menuToggle={menuToggle}
        onRestart={async () => {
          await restartChatSession();
        }}
        theme={theme}
      />
    </View>
  );
};

export default ChatScreen;
