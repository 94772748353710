import React from "react";
import styles from "./styles";
import { View } from "react-native";
import WavingDots from "./WavingDots";

export const TypingIndicator = ({ theme }) => {
  return (
    <View style={styles.typing}>
      <WavingDots
        color={theme.colors.line.default}
        dots={3}
        theme={theme}
      />
    </View>
  );
};
